(function ($) {
  //------------------------------------------------------//
  // header carousel  
  //------------------------------------------------------//
  $('.owl-header').owlCarousel({
    loop: true,
    margin: 0,
    nav: false,
    dots: false,
    items: 1,
    autoplay: true,
    // autoplayTimeout:
  });
  // END header carousel  -------------------------------------//
  gsap.registerPlugin(ScrollTrigger);

  ScrollTrigger.defaults({
    // Defaults are used by all ScrollTriggers
    //events: onEnter onLeave onEnterBack onLeaveBack
    toggleActions: "restart pause resume pause",
    //options: play, pause, resume, reset, restart, complete, reverse,none
    // markers: true // Easily remove markers for production 
  });
  //------------------------------------------------------//
  // ⭐️ Kenmerken 
  //------------------------------------------------------//
  if (document.querySelector('.ACF-kenmerken-type-animation')) {

    function distributeByPosition(vars) {
      var ease = vars.ease,
        from = vars.from || 0,
        base = vars.base || 0,
        axis = vars.axis,
        ratio = { center: 0.5, end: 1, edges: 0.5 }[from] || 0,
        distances;
      return function (i, target, a) {
        var l = a.length,
          originX,
          originY,
          x,
          y,
          d,
          j,
          minX,
          maxX,
          minY,
          maxY,
          positions;
        if (!distances) {
          distances = [];
          minX = minY = Infinity;
          maxX = maxY = -minX;
          positions = [];
          for (j = 0; j < l; j++) {
            d = a[j].getBoundingClientRect();
            x = (d.left + d.right) / 2; //based on the center of each element
            y = (d.top + d.bottom) / 2;
            if (x < minX) {
              minX = x;
            }
            if (x > maxX) {
              maxX = x;
            }
            if (y < minY) {
              minY = y;
            }
            if (y > maxY) {
              maxY = y;
            }
            positions[j] = { x: x, y: y };
          }
          originX = isNaN(from) ? minX + (maxX - minX) * ratio : positions[from].x || 0;
          originY = isNaN(from) ? minY + (maxY - minY) * ratio : positions[from].y || 0;
          maxX = 0;
          minX = Infinity;
          for (j = 0; j < l; j++) {
            x = positions[j].x - originX;
            y = originY - positions[j].y;
            distances[j] = d = !axis ? Math.sqrt(x * x + y * y) : Math.abs(axis === "y" ? y : x);
            if (d > maxX) {
              maxX = d;
            }
            if (d < minX) {
              minX = d;
            }
          }
          distances.max = maxX - minX;
          distances.min = minX;
          distances.v = l =
            (vars.amount || vars.each * l || 0) * (from === "edges" ? -1 : 1);
          distances.b = l < 0 ? base - l : base;
        }
        l = (distances[i] - distances.min) / distances.max;
        return distances.b + (ease ? ease.getRatio(l) : l) * distances.v;
      };
    }
    function textPop(item) {
      const timeline = gsap.timeline();

      timeline
        // .to(".alloy-kenmerken .content .item", {scale: 0 })
        .from(item, {
          scale: 0,
          autoAlpha: 0,
          transformOrigin: "50% 50%",
          duration: 0.6,
          ease: "back.out(2)"
        })
        .to(
          ".alloy-kenmerken svg #shapes g",
          {
            x: "-=10",
            y: "-=10",
            yoyo: true,
            ease: CustomEase.create(
              "custom",
              "M0,0 C0,0.444 0.204,1 0.51,1 0.802,1 1,0.464 1,0 "
            ),
            stagger: distributeByPosition({
              from: "center",
              amount: 0.6,
            })
          },
          "-=0.4"
        )
        .to(item, { scale: 0 }, "+=2")
        ;
      return timeline;
    }


    const timelineText = gsap.timeline({ repeat: -1 });
    document.querySelectorAll(".ACF-kenmerken-type-animation .content .item").forEach(function (item) {

      timelineText.add(textPop(item));

    });

    const timeline = gsap.timeline(
      // { repeat: -1, repeatDelay: 1 }
    );

    const timelineKenmerken = gsap.timeline({
      repeat: -1,
      scrollTrigger: {
        id: "KENMERKEN", // Custom label to the marker
        trigger: ".ACF-kenmerken-type-animation", // What element triggers the scroll
        // scrub: 1, // Add a small delay of scrolling and animation. `true` is direct
        start: "top bottom-=20%", // Can be top, center, bottom 
        end: "+=100% bottom-=25%", // Can be top, center, bottom 
        pin: false, // Pin the element true or false
      }
    });

    timelineKenmerken
      .from(".ACF-kenmerken-type-animation svg #shapes g", {
        y: 442,
        x: 884,
        autoAlpha: 0,
        svgOrigin: "center center",
        ease: "back.out(1.5)",
        stagger: distributeByPosition({
          from: "center",
          amount: 0.6
        })
      })
      .add(timelineText);
  };

  // END timeline
  // END ⭐️ Kenmerken -------------------------------------//
  //------------------------------------------------------//
  // 💬 Quote 
  //------------------------------------------------------//
  if (document.querySelectorAll(".ACF-quote")) {

    document.querySelectorAll(".ACF-quote").forEach(function (container) {

      const item = container.querySelectorAll("blockquote");

      const timeline = gsap.timeline({
        scrollTrigger: {
          id: "QUOTE", // Custom label to the marker
          trigger: container, // What element triggers the scroll
          scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
          start: "top bottom-=20%", // Can be top, center, bottom 
          end: "+=100% bottom-=25%", // Can be top, center, bottom 
          // pin: true,
        }
      });
      const leftRight = gsap.utils.wrap(["-100", "100"]);
      timeline.from(item, {
        opacity: 0,
        x: leftRight,
        stagger: {
          each: 0.2
        }
      });

    });
  }
  // END 💬 Quote -------------------------------------//
  //------------------------------------------------------//
  // Logo animation 
  //------------------------------------------------------//
  const defaultEase = "back.out(1.7)";

  const timelineZ = gsap.timeline();

  timelineZ
    .set(["#z-middle", "#z-dots"], { autoAlpha: 1 })
    .from(["#z-middle", "#z-dots"], {
      rotation: "+=45",
      svgOrigin: "50.593 66.206",
      ease: defaultEase
    })
    .set(["#z-top", "#z-bottom"], { autoAlpha: 1 })
    .from(
      ["#z-top"],
      { rotation: "-=45", svgOrigin: "108.348 15.799", ease: defaultEase },
      "sameTime"
    )
    .from(
      ["#z-bottom"],
      { rotation: "-=45", svgOrigin: "50.593 66.206", ease: defaultEase },
      "sameTime"
    );
  const timelineW = gsap.timeline();
  timelineW
    .set(["#w-middle-right", "#w-middle-left", "#w-dots-left", "#w-dots-right"], {
      autoAlpha: 1
    })
    .from(
      ["#w-middle-right", "#w-dots-right"],
      { rotation: "+=45", svgOrigin: "78.013 88.749", ease: defaultEase },
      "sameTime"
    )
    .from(
      ["#w-middle-left", "#w-dots-left"],
      { rotation: "-=45", svgOrigin: "78.013 88.749", ease: defaultEase },
      "sameTime"
    )
    .set(["#w-right", "#w-left"], { autoAlpha: 1 })
    .from(
      ["#w-right"],
      { rotation: "-=45", svgOrigin: "104.015 140.314", ease: defaultEase },
      "sameTimeTwo"
    )
    .from(
      ["#w-left"],
      { rotation: "+=45", svgOrigin: "52.012 140.314", ease: defaultEase },
      "sameTimeTwo"
    );

  const timelineLogo = gsap.timeline({ repeat: -1, repeatDelay: 10 });

  timelineLogo
    .add(timelineZ, "sameTime")
    .add(timelineW, "sameTime");

  // GSDevTools.create({ animation: timelineLogo, css: { zIndex: 3 } });
  /* Logo scrollTrigger ----------------------------------------- */
  // if (document.querySelectorAll(".ACF-logo")) {
  //   document.querySelectorAll(".ACF-logo").forEach(function (container) {

  //     const timeline = gsap.timeline({
  //       scrollTrigger: {
  //         id: "LOGO", // Custom label to the marker
  //         trigger: container, // What element triggers the scroll
  //         scrub: 0.5, // Add a small delay of scrolling and animation. `true` is direct
  //         start: "top bottom-=20%", // Can be top, center, bottom 
  //         end: "+=100% bottom-=25%", // Can be top, center, bottom 
  //         // pin: true,
  //       }
  //     });

  //     const timelineACFZ = gsap.timeline();

  //     timelineACFZ
  //       .set([".ACF-logo #z-middle", ".ACF-logo #z-dots"], { autoAlpha: 1 })
  //       .from([".ACF-logo #z-middle", ".ACF-logo #z-dots"], {
  //         rotation: "+=45",
  //         svgOrigin: "50.593 66.206",
  //         ease: defaultEase
  //       })
  //       .set([".ACF-logo #z-top", "#z-bottom"], { autoAlpha: 1 })
  //       .from(
  //         [".ACF-logo #z-top"],
  //         { rotation: "-=45", svgOrigin: "108.348 15.799", ease: defaultEase },
  //         "sameTime"
  //       )
  //       .from(
  //         [".ACF-logo #z-bottom"],
  //         { rotation: "-=45", svgOrigin: "50.593 66.206", ease: defaultEase },
  //         "sameTime"
  //       );
  //     const timelineACFW = gsap.timeline();
  //     timelineACFW
  //       .set([".ACF-logo #w-middle-right", ".ACF-logo #w-middle-left", ".ACF-logo #w-dots-left", ".ACF-logo #w-dots-right"], {
  //         autoAlpha: 1
  //       })
  //       .from(
  //         [".ACF-logo #w-middle-right", ".ACF-logo #w-dots-right"],
  //         { rotation: "+=45", svgOrigin: "78.013 88.749", ease: defaultEase },
  //         "sameTime"
  //       )
  //       .from(
  //         [".ACF-logo #w-middle-left", ".ACF-logo #w-dots-left"],
  //         { rotation: "-=45", svgOrigin: "78.013 88.749", ease: defaultEase },
  //         "sameTime"
  //       )
  //       .set([".ACF-logo #w-right", ".ACF-logo #w-left"], { autoAlpha: 1 })
  //       .from(
  //         [".ACF-logo #w-right"],
  //         { rotation: "-=45", svgOrigin: "104.015 140.314", ease: defaultEase },
  //         "sameTimeTwo"
  //       )
  //       .from(
  //         [".ACF-logo #w-left"],
  //         { rotation: "+=45", svgOrigin: "52.012 140.314", ease: defaultEase },
  //         "sameTimeTwo"
  //       );
  //     timeline
  //       .add(timelineACFZ, "sameTime")
  //       .add(timelineACFW, "sameTime");

  //   });

  // }

  // END Logo animation -------------------------------------//



  // document.querySelectorAll(".slider").forEach((container) => {
  // 	// Setup variables
  // 	const items = container.querySelectorAll(".slide"),
  // 		itemsMax = items.length - 1;
  // 	const next = container.querySelector(".next"),
  // 		prev = container.querySelector(".prev"),
  // 		dots = container.querySelector(".dots");
  // 	let index = 0;
  // 	const move = 100;
  // 	const timer = 5000;
  // 	const duration = 0.5;
  // 	let autoplay;
  // 	//--------------------------------//
  // 	// 💾 Inital setup
  // 	//--------------------------------//
  // 	function init() {
  // 		// Create 🟣 Dots
  // 		items.forEach((item, index) => {
  // 			const dot = document.createElement("li");
  // 			if (index === 0) dot.classList.add("active"); // Set the first dot to active
  // 			// Allow clicking the dots
  // 			dot.addEventListener("click", () => {
  // 				slideLogic(false, index);
  // 			});

  // 			if (dots) dots.appendChild(dot);
  // 		});
  // 		// Animate the first element in
  // 		gsap.set(items, { autoAlpha: 0 });
  // 		gsap.set([".slider", items[index]], { autoAlpha: 1 });
  // 		gsap.from(items[index], { autoAlpha: 0, x: move });
  // 		autoplay = window.setTimeout(slideLogic, timer);
  // 	}
  // 	// Run inital setup
  // 	init();
  // 	// END 💾 Inital setup --------------//

  // 	//--------------------------------//
  // 	// ☝️ Touch
  // 	//--------------------------------//
  // 	if (window.Draggable) {
  // 		Draggable.create(items, {
  // 			type: "x", // Drag only on the X axis
  // 			zIndexBoost: false,
  // 			onDragStart: function () {
  // 				window.clearTimeout(autoplay); // disable autoplay
  // 				slideLogic(this.getDirection() === "right" ? true : false);
  // 			}
  // 		});
  // 	}
  // 	// END ☝️ Touch  --------------//

  // 	//--------------------------------//
  // 	// Set active 🟣 dot
  // 	//--------------------------------//
  // 	function dotActive(index) {
  // 		const dotsAll = dots.querySelectorAll("li");
  // 		dotsAll.forEach((dot) => {
  // 			dot.classList.remove("active");
  // 		});
  // 		dotsAll[index].classList.add("active");
  // 	}
  // 	// END Set active 🟣 dot --------------//

  // 	//--------------------------------//
  // 	// 🖼️ Slide animation
  // 	//--------------------------------//
  // 	function slideAnimation(index, moveIn, outIn) {
  // 		// Reset the properties you are animating below
  // 		// Ohter wise they animate from that positon (probalby not visable)
  // 		gsap.set(items, { xPercent: 0, scale: 1 });
  // 		gsap.set(items[moveIn], { autoAlpha: 1 });

  // 		const text = new SplitText(items[moveIn].querySelector("h1"), {
  // 			type: "chars"
  // 		});

  // 		// The animation
  // 		const tl = gsap.timeline({
  // 			defaults: {
  // 				duration: duration
  // 			},
  // 			onStart: animationStart,
  // 			onComplete: animationDone
  // 		});
  // 		// Move out slide
  // 		tl.to(items[index], { xPercent: outIn[0], scale: 0.4 });
  // 		tl.set(items[index], { autoAlpha: 0 }); // Hide moved out slide
  // 		// move in slide
  // 		tl.from(items[moveIn], { xPercent: outIn[1] }, "<");
  // 		tl.from(items[moveIn], { rotate: 360 });
  // 		tl.from(items[moveIn], { rotationY: 360 });
  // 		tl.from(items[moveIn], { scale: 0.4 });
  // 		// Animate text
  // 		tl.from(text.chars, { y: 100, opacity: 0, stagger: 0.05 });
  // 	}
  // 	function animationStart() {
  // 		container.classList.add("running");
  // 	}
  // 	function animationDone() {
  // 		toggleButtons();
  // 		autoplay = window.setTimeout(slideLogic, timer);
  // 		container.classList.remove("running");
  // 		gsap.set(items, { x: 0 });
  // 	}
  // 	// END 🖼️ Slide animation --------------//

  // 	//--------------------------------//
  // 	// Slider 🎛️ logic
  // 	//--------------------------------//
  // 	function slideLogic(prev, customMoveIn) {
  // 		toggleButtons(); // Disable buttons
  // 		window.clearTimeout(autoplay); // disable autoplay
  // 		let outIn = [-move, move];
  // 		if (prev) outIn.reverse();
  // 		let moveIn;
  // 		// Check if moveIn is passed with the function
  // 		if (typeof customMoveIn === "undefined") {
  // 			if (prev) {
  // 				moveIn = index === 0 ? itemsMax : index - 1;
  // 			} else {
  // 				moveIn = index === itemsMax ? 0 : index + 1;
  // 			}
  // 		} else {
  // 			moveIn = customMoveIn;
  // 		}
  // 		if (dots) dotActive(moveIn); // Set active dot
  // 		slideAnimation(index, moveIn, outIn); // Animation function
  // 		// Changing the next index
  // 		if (typeof customMoveIn === "undefined") {
  // 			if (prev) {
  // 				index === 0 ? (index = itemsMax) : index--;
  // 			} else {
  // 				index === itemsMax ? (index = 0) : index++;
  // 			}
  // 		} else {
  // 			index === itemsMax ? (index = 0) : (index = customMoveIn++);
  // 		}
  // 	}
  // 	// END Slider 🎛️ logic --------------//

  // 	//--------------------------------//
  // 	// Button 🎛️ control
  // 	//--------------------------------//
  // 	function toggleButtons() {
  // 		if (next) next.disabled = !next.disabled;
  // 		if (prev) prev.disabled = !prev.disabled;
  // 	}
  // 	if (next) next.addEventListener("click", () => slideLogic());
  // 	if (prev) prev.addEventListener("click", () => slideLogic(true));
  // 	// END Button 🎛️ control --------------//
  // });


})(jQuery); // Fully reference jQuery after this point.

